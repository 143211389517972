import { createRouter, createWebHistory } from "vue-router";
import PeopleEntryView from "../views/PeopleEntryView.vue";

const routes = [
  {
    path: "/:id?",
    component: PeopleEntryView,
    name: "PeopleEntryView",
    props: true
  },
  {
    path: "/find-people",
    name: "PeopleFinderView",
    component: () => import("../views/PeopleFinderView.vue")
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
