<template>
  <div class="pt3">
    <label class="primary">Subject tags</label><br />
    <w-tag
      v-for="tag in subjectTags"
      :key="tag.id"
      class="ma1"
      color="white"
      bg-color="indigo"
      md
    >
      {{ tag.title }}
    </w-tag>
  </div>
  <div class="pt3">
    <label class="primary">Linked organizations</label><br />
    <w-tag
      v-for="tag in relatedOrganizations"
      :key="tag.id"
      class="ma1"
      color="primary"
      md
    >
      {{ tag.title }}
    </w-tag>
  </div>
  <div class="pt3">
    <label class="primary">Linked people</label><br />
    <w-tag
      v-for="person in linkedPersons"
      :key="person.personId"
      class="ma1"
      color="primary"
      md
    >
      <a :href="'/' + person.personId" target="_blank">{{ person.fullName }}</a>
    </w-tag>
  </div>
  <div class="pt3">
    <label class="primary">Linked publications</label><br />
    <w-tag
      v-for="publication in linkedPublications"
      :key="publication.title"
      class="ma1"
      color="primary"
      md
    >
      {{ publication.title }}
    </w-tag>
  </div>
</template>

<script>
export default {
  components: {},
  name: "PeopleTags",
  props: {
    relatedOrganizations: Array,
    subjectTags: Array,
    linkedPublications: Array,
    linkedPersons: Array
  },
  data() {
    return {};
  }
};
</script>
