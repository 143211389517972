<template>
  <w-notification
    v-model="notification.successful"
    :timeout="notification.timeout"
    success
    plain
    round
    shadow
    top
    center
    transition="bounce"
    >Your changes were saved</w-notification
  >

  <w-notification
    v-model="notification.unsuccessful"
    :timeout="notification.timeout"
    error
    plain
    round
    shadow
    top
    center
    transition="bounce"
    >An error occured</w-notification
  >

  <w-form v-model="valid">
    <w-flex column justify-center align-center class="box">
      <div class="pt3">
        <w-select
          :items="titles"
          v-model="person.title"
          item-value-key="id"
          label="Title"
        />
      </div>
      <div class="pt3">
        <w-input
          v-model="person.firstName"
          label="First Name"
          :validators="[validators.required, validators.noNumbers]"
          maxlength="100"
        />
      </div>
      <div class="pt3">
        <w-input
          v-model="person.lastName"
          label="Last Name"
          :validators="[validators.required, validators.noNumbers]"
          maxlength="100"
        />
      </div>
      <div class="pt3">
        <w-input
          v-model="person.initials"
          label="Initials"
          :validators="[validators.noNumbers]"
          maxlength="5"
        />
      </div>
      <div class="pt3">
        <w-input v-model="person.email" type="email" label="Email" />
      </div>
      <div class="pt3">
        <w-input
          v-model="person.jobTitle"
          label="Job Title"
          :validators="[validators.noNumbers]"
        />
      </div>
      <div class="pt3">
        <w-input
          v-model="person.phone"
          type="tel"
          label="Phone Number"
          :validators="[validators.onlyNumbers]"
        />
      </div>

      <div class="pt3">
        <w-checkbox v-model="person.vip">VIP</w-checkbox>
      </div>
      <div class="pt3">
        <w-input v-model="person.contactSource" label="Contact source" />
      </div>
      <div v-if="person.lastModifiedDate" class="pt3">
        <w-input
          v-model="person.lastModifiedDate"
          label="Last modified date"
          readonly
        />
      </div>
      <div class="pt3">
        <people-tags
          :relatedOrganizations="person.relatedOrganizations"
          :subjectTags="person.subjectTags"
          :linkedPublications="person.linkedPublications"
          :linkedPersons="person.linkedPersons"
        ></people-tags>
      </div>
      <div class="pt3">
        <w-button
          :loading="saveButtonLoading"
          type="submit"
          :disabled="valid === false"
          @click="save()"
          >Save</w-button
        >
      </div>
    </w-flex>
  </w-form>
</template>

<script>
import PersonTitle from "@/model/PersonTitle.js";
import PeopleService from "@/services/PeopleService.js";
import PeopleTags from "./PeopleTags.vue";

export default {
  components: { PeopleTags },
  name: "PeopleEntry",
  props: {
    id: String,
    searchInProgress: Boolean
  },
  data() {
    return {
      person: {},
      saveButtonLoading: false,
      titles: PersonTitle.asList(),
      valid: null,
      validators: {
        required: value => !!value || "This is a required field.",
        onlyNumbers: value =>
          value === "" ||
          value === null ||
          value.match(/^[0-9]+$/) != null ||
          "Only numbers are allowed.",
        noNumbers: value => value.match(/^\D*$/) || "No numbers are allowed."
      },
      filters: {
        dateTime: function(dateTime) {
          const day = dateTime.getDate();
          const month = dateTime.getMonth();
          const year = dateTime.getFullYear();
          return `${day}-${month}-${year}`;
        }
      },
      notification: {
        show: false,
        successful: false,
        unsuccessful: false,
        timeout: 2000
      }
    };
  },
  methods: {
    save() {
      if (!this.valid) return;
      this.saveButtonLoading = true;
      debugger;
      let person = Object.assign(this.person);
      person.title = this.person.title?.id || this.person.title;
      PeopleService.save(person)
        .then(response => {
          this.person.id = response.data.id;
          this.person.lastModifiedDate = response.data.lastModifiedDate;
          this.saveButtonLoading = false;
          this.notification.successful = true;
        })
        .catch(() => {
          this.saveButtonLoading = false;
          this.notification.unsuccessful = true;
        });
    }
  },
  created() {
    if (this.id) {
      this.saveButtonLoading = true;
      PeopleService.getPerson(this.id)
        .then(response => {
          let person = response.data;
          person.relatedOrganizations = person.tags.filter(
            tag => tag.tagType == 1
          );
          person.subjectTags = person.tags.filter(tag => tag.tagType == 2);
          this.person = person;
          (this.person.title = this.person.title
            ? PersonTitle.fromLabel(this.person.title)
            : null),
            (this.saveButtonLoading = false);
          this.notification.show = true;
        })
        .catch(() => {
          this.saveButtonLoading = false;
          this.notification.show = true;
          this.notification.unsuccessful = true;
        });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.box div {
  width: 300px;
  text-align: left;
}
</style>
