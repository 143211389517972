import axios from "axios";
const API_URL = "https://flows-people.herokuapp.com";
//const API_URL = "http://localhost:9090";
const apiClient = axios.create({
  baseURL: API_URL /*,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }*/
});

export default {
  getPersons(criteria) {
    return apiClient.get("/", { params: criteria });
  },
  getPerson(personId) {
    return apiClient.get(`/${personId}`);
  },
  save(person) {
    return apiClient.post("/", person);
  }
};
