const PersonTitles = Object.freeze([
  { id: 28, label: "BSc." },
  { id: 7, label: "Dr." },
  { id: 9, label: "Dr. ing." },
  { id: 8, label: "Dr. ir." },
  { id: 15, label: "Dr. ir. drs." },
  { id: 11, label: "Drs." },
  { id: 13, label: "Drs. ir." },
  { id: 6, label: "Ing." },
  { id: 1, label: "Ir." },
  { id: 17, label: "Ir. drs." },
  { id: 34, label: "Mr." },
  { id: 2, label: "Mr. ir." },
  { id: 33, label: "MSc." },
  { id: 40, label: "MA" },
  { id: 12, label: "Prof. dr." },
  { id: 14, label: "Prof. dr. ing." },
  { id: 5, label: "Prof. dr. ir." },
  { id: 3, label: "Prof. dr. mr." },
  { id: 35, label: "Prof. dr. mr. ir." },
  { id: 4, label: "Prof. drs. ir." },
  { id: 20, label: "Prof. em." },
  { id: 19, label: "Prof. ing." },
  { id: 10, label: "Prof. ir." },
  { id: 18, label: "Prof. ir. drs." },
  { id: 16, label: "Prof. mr. dr." },
  { id: 99, label: "Not known" },
  { id: 100, label: "No title" }
]);

export default {
  asList: () => PersonTitles,
  fromId(personTitleId) {
    return this.asList().filter(item => item.id == personTitleId)[0];
  },
  fromLabel(personTitleLabel) {
    return this.asList().filter(item => item.label === personTitleLabel)[0];
  }
};
