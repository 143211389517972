import { createApp, h } from "vue";
import App from "./App.vue";
import WaveUI from "wave-ui";
import "wave-ui/dist/wave-ui.css";
import router from "./router";
import axios from "axios";
import VueAxios from "vue-axios";
const app = createApp({
  render: () => h(App)
}).use(router, axios, VueAxios);

new WaveUI(app, {
  // Some Wave UI options.
});

app.mount("#app");
