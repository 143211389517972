<template>
  <PeopleEntry :id="id" />
</template>

<script>
import PeopleEntry from "@/components/PeopleEntry.vue";
export default {
  name: "PeopleEntryView",
  components: {
    PeopleEntry
  },
  props: {
    id: String
  }
};
</script>
